@import "../../styles/_variables";

.home-container {
  background-color: $light-white-color;

  @media screen and (max-width: 1024px) {
    padding-top: 100px
  }

  h1 {
    color: $heading-dark-color;
    font-size: 2.5rem;
  }
}

.home-container-head {
  padding: 50px 100px 100px;
  background-image: url("../../resources/images/backgrounds/bg_home_pg.webp");
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1399px){
    padding: 50px 100px 100px 0;
  }

  .alice-carousel__prev-btn{
    display: none;
  }

  @media screen and (max-width: 1399px) {
    .alice-carousel__next-btn {
      right: -100px;
    }
    .alice-carousel__prev-btn {
      left: -100px;
      display: block;
    }
  }

  @media screen and (max-width: 410px){
    .alice-carousel__next-btn {
      width: 50px;
      right: -45px;
    }
    .alice-carousel__prev-btn {
      width: 50px;
      left: 30px;
    }
  }

  &_content{
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 1024px){
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }
  }

  .home-container-now-live{
    width: 680px;

    &_up{
      display: flex;
      align-items: center;

      @media screen and (max-width: 1024px){
        justify-content: center;
      }


      span{
        color: #CD9934;
        font-size: 24px;
        font-family: 'SairaRegular', sans-serif;


        @media screen and (max-width: 690px){
          font-size: 16px;
        }
      }
    }

    .now-live-content{
      @media screen and (max-width: 1024px){
        text-align: center;
      }

      h4{
        font-size: 56px;
        color: #ffffff;
        margin: 16px 0 6px 0;
        font-family: 'SairaMedium', sans-serif;
        line-height: 1;

        @media screen and (max-width: 690px){
          margin: 30px auto;
          font-size: 31px;
          width: 350px;
        }
      }

      p{
        font-size: 24px;
        color: #ffffff;
        font-family: 'SairaRegular', sans-serif;

        @media screen and (max-width: 690px){
          width: 380px;
          margin: 0 auto;
          font-size: 16px;
        }
      }

      button{
        margin-top: 40px;
        width: 216px;
        height: 60px!important;
        background: #CD9934 0 0 no-repeat padding-box;
        border-radius: 30px;
        opacity: 1;
        font-size: 24px;
        color: #ffffff;
        font-family: 'SairaBold', sans-serif;

        @media screen and (max-width: 1024px){
          margin: 40px auto;
        }
      }
    }


  }

  .home-container-head-banner{
    .banner-image{
      width: 445px;
      height: 401px;
      img{
        width: 100%;
      }
    }
  }


  .home-container-heading {
    align-items: center;

    h1 {
      color: white;
    }

    display: flex;
  }
}

.home-block {
  padding-bottom: 50px;
  min-height: 600px;

  .placeholder-loading-items-wrapper {
    width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  }
}

.top-collection, .new-listings {
  background-color: #F0F2F4;
}

.live-auctions {
  background-color: #FFFFFF;
}

.home-block-heading {
  padding: 0 30px 0 100px;
  display: flex;
  align-items: center;

  > p {
    margin-left: auto;
    color: $border-color;
    text-decoration: underline;
  }


  .blockchain-buttons{
    display: flex;
    align-items: center;
    margin-left: 15px;
    gap: 15px;

    button:first-child{
      
      background: #FFFFFF;
      color: #000000;
    }

    @media screen and (max-width: 1200px){
      margin-top: 15px;
    }
  }
}

.home-block-content {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;

  .alice-carousel{
    min-height: auto!important;
  }

  .alice-carousel__wrapper{
    height: auto!important;
  }
}

.home-head-line {
  border-top: 2px solid $border-color;
  width: 150px;
  margin-left: 100px;
}

@media screen and (max-width: 1200px) {
  .home-container-head {
    padding: 10px;

    .home-container-heading, {
      padding: 10px;
      flex-direction: column;
    }
  }

  .home-container-head-content, .home-block-content {
    width: auto;
    align-items: center;
    flex-direction: column;
  }

  .home-container-head-single {
    flex-direction: column-reverse;

    @media screen and (max-width: 500px) {
      width: 95%;
    }

    .head-single-info {
      align-self: auto;
    }
  }

  .home-block-heading {
    flex-direction: column;
    padding: 10px;
    align-items: flex-start;
  }
}

.alice-carousel {
  min-height: 500px;
}

.alice-carousel__next-btn [data-area]::after, .alice-carousel__prev-btn [data-area]::after {
  content: '';
}

.alice-carousel__next-btn, .alice-carousel__prev-btn {
  position: absolute;
  background-image: url("../../resources/images/rightarrow_icon.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 92px;
  height: 70px;
  padding: 0;
}

.alice-carousel__prev-btn {
  background-image: url("../../resources/images/leftarrow_icon.webp");
  top: 40%;
  left: -100px;
}

.alice-carousel__next-btn {
  background-image: url("../../resources/images/rightarrow_icon.webp");
  right: -100px;
  top: 40%;
}

.alice-carousel__stage-item {
  line-height: initial;
}

.alice-carousel__prev-btn-item.__inactive, .alice-carousel__next-btn-item.__inactive, .alice-carousel__next-btn-wrapper, .alice-carousel__prev-btn-wrapper, .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  width: 100%;
  height: 100%;
  padding: 0;

  span {
    width: 100%;
    height: 100%;
    display: block;

    &:after {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.alice-carousel__dots {
  display: none;
}

@media screen and (max-width: 1600px) {
  .alice-carousel__next-btn {
    right: -100px;
  }
  .alice-carousel__prev-btn {
    left: -100px;
  }
}

@media screen and (max-width: 1366px) {
  .alice-carousel__prev-btn {
    position: relative;
    text-align: center;
    display: inline-block;
    float: left;
    left: auto;
    top: auto;
    right: auto;
    margin-left: 50%;
    transform: translate(-98px, 0);
  }

  .alice-carousel__next-btn {
    position: relative;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    left: auto;
    top: auto;
    right: auto;
    transform: translate(-86px, 0);

  }
}

@media screen and (max-width: 370px){
  .alice-carousel__next-btn, .alice-carousel__prev-btn {
    width: 50px;
    height: 50px;
    transform: translate(-50px, 0);
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}



//.home-container-head-single {
//  img {
//    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
//    -webkit-transition: clip-path .5s linear;
//  }
//
//  img:hover {
//    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%)
//  }
//}
