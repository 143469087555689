@import "../../styles/_variables";

.listed-item-wrapper {
  width: 100%;
  //height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;
  font-family: $main-font-family;
  background: $light-white-color;

  @media screen and (max-width: 1024px) {
    padding-top: 100px;
    height: max-content;
  }

  .listed-item-container {
    width: 1024px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 40px 5px;
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 500px) {
      margin-top: 0;
      padding: 20px;
    }
  }

  .listed-item-info {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .item-description {
      font-size: 20px;
      font-weight: 600;

      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }
    }

    .mint-info {
      font-size: 14px;
      color: gray;
      font-weight: 500;
      margin-top: 7px;
    }

    .category {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-left: 20px;

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      img {
        width: 40px;
        margin-right: 10px;
      }
    }

    .listed-item-info-buy {
      border: 2px solid #edeeee;
      border-radius: 20px;
      padding: 16px;
      display: flex;
      flex-direction: column;

      p {
        font-weight: 700;
      }

      .listed-item-owner {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .name-icon {
          font-size: 20px;
          font-weight: 900;
          color: $light-white-color;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }

        .bg-seller {
          background: $main-light-color;
        }

        .bg-buyer {
          background: $secondary-dark-color;
        }

        .bg-creator {
          background: $heading-dark-color;
        }
      }

      .price {
        font-size: 18px;
        margin-top: 20px;
      }

      .fees-burned{
        font-size: 13px;
        color: #d69700;
      }

      .listed-item-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;

        @media screen and (max-width: 500px) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        button {
          margin-right: 30px;
          min-width: 130px;

          @media screen and (max-width: 500px) {
            margin-top: 20px;
            width: 140px;
          }
        }
      }
    }
  }
}

.listed-item-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 500px;
  min-height: 460px;

  @media screen and (max-width: 1024px) {
    align-items: flex-end;
    width: 400px;
    min-height: 200px;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  video {
    border-radius: 20px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5);
  }

  img {
    width: 100%;
    height: 600px;
    border-radius: 20px;
    object-fit: contain;
    //padding: 40px 20px;

    @media screen and (max-width: 1024px) {
      height: auto;
      max-height: 300px;
    }
  }
}
